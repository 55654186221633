import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { Avatar, Button, Col, Row } from "antd"
import cx from "classnames"
import React, { useEffect } from "react"
import { useState } from "react"
import { Helmet } from "react-helmet"
import { useMedia } from "react-use-media"
import { io } from "socket.io-client"

import SelectMenu from "../../components/SelectMenu"
import TranslateWrapper from "../../components/TranslateWrapper"
import { useNotificationsCounter } from "../../hooks/use-notifications-counter"
import { getTimeHumanized } from "../../utils/date"
import styles from "./style.module.less"

const activeFiltersMap = {
  ALL: "ALL",
  NEW_POST: "NEW_POST",
  NEW_LIKE: "NEW_LIKE",
  NEW_COMMENT: "NEW_COMMENT",
  COMMENT_REPLY: "COMMENT_REPLY",
  NEW_ESSAY: "NEW_ESSAY",
  NEW_FOLLOWER: "NEW_FOLLOWER",
  PAYMENT: "PAYMENT",
}

let newSocket = { close: () => {} }

var activeFiltersMapOption = []
activeFiltersMapOption["ALL"] = translate("all")
activeFiltersMapOption["NEW_POST"] = translate("posts")
activeFiltersMapOption["PAYMENTNEW_LIKE"] = translate("likes")
activeFiltersMapOption["NEW_COMMENT"] = translate("comments")
activeFiltersMapOption["COMMENT_REPLY"] = translate("comments")
activeFiltersMapOption["NEW_ESSAY"] = translate("essays")
activeFiltersMapOption["NEW_FOLLOWER"] = translate("followers")
activeFiltersMapOption["PAYMENT"] = translate("paymentTitle")

const initialOptions = [
  {
    label: "Todos",
    value: "ALL",
  },
  {
    label: "Ensaios",
    value: "NEW_ESSAY",
  },
  {
    label: "Postagens",
    value: "NEW_POST",
  },
  {
    label: "Comentários",
    value: "NEW_COMMENT",
  },
]

const NotificationsPage = () => {
  const { notifications, authSite } = useStores()
  const [, , clearCounter] = useNotificationsCounter()
  const { user } = authSite

  const [notificationsList, setNotificationsList] = useState([])
  const [activeFilter, setActiveFilter] = useState(activeFiltersMap.ALL)
  const [filteredNotifications, setFilteredNotifications] = useState([])

  const { listUserNotifications } = notifications

  const isTablet = useMedia({ maxWidth: 1024 })
  const isDesktop = useMedia({ minWidth: 1024 })

  const [widthContainerButtons, setWidthContainerButtons] = useState(0)
  const [widthContainerNotifications, setWidthContainerNotifications] =
    useState(0)
  const [widthContainerButtonsTablet, setWidthContainerButtonsTablet] =
    useState(0)
  const [
    widthContainerNotificationsTablet,
    setWidthContainerNotificationsTablet,
  ] = useState(0)
  const [options, setOptions] = useState(initialOptions)

  async function fetchNotifications() {
    const not = await listUserNotifications(user.id)
    setNotificationsList(not)
    setFilteredNotifications(not)
  }

  useEffect(() => {
    ;(async () => {
      await fetchNotifications()
    })()
    clearCounter()
  }, [])

  useEffect(() => {
    ;(async () => {
      newSocket = await io.connect(process.env.API_URL, { secure: true })
      await newSocket.on(`msgToClient-${user.id}`, () => {
        fetchNotifications()
      })
    })()
    return () => {
      newSocket.close()
    }
  }, [])

  useEffect(() => {
    addOpstions()
    calculateWidthContainer()
  }, [])

  const addOpstions = () => {
    if (user && user.type) {
      setOptions([
        ...options,
        {
          label: "Curtidas",
          value: "NEW_LIKE",
        },
        {
          label: "Seguidores",
          value: "NEW_FOLLOWER",
        },
      ])
    } else if (user && !user.type)
      setOptions([
        ...options,
        {
          label: "Pagamento",
          value: "PAYMENT",
        },
      ])
  }

  // console.log(options)
  const calculateWidthContainer = () => {
    let countButtons = 0
    let buttonSize = 120
    let buttonSizeTablet = 100
    const marginButtons = 14

    /* subscriber  */
    if (user && !user.type) {
      countButtons = 5
    } /* creator  */ else if (user && user.type) {
      countButtons = 6
    }

    // console.log(countButtons, buttonSize)
    const result =
      countButtons * buttonSize + (countButtons - 1) * marginButtons
    const resultTablet =
      countButtons * buttonSizeTablet + (countButtons - 1) * marginButtons

    setWidthContainerButtons(result)
    setWidthContainerNotifications(result + 37)

    setWidthContainerButtonsTablet(resultTablet)
    setWidthContainerNotificationsTablet(resultTablet + 37)
  }

  const setFilter = (filter) => {
    // console.log(filter)

    if (isDesktop) setActiveFilter(filter)

    if (filter === activeFiltersMap.ALL) {
      setFilteredNotifications(notificationsList)
    } else if (
      filter === activeFiltersMap.NEW_COMMENT ||
      filter === activeFiltersMap.COMMENT_REPLY
    ) {
      setFilteredNotifications(
        notificationsList.filter(
          (item) =>
            item.notificationType === activeFiltersMap.NEW_COMMENT ||
            item.notificationType === activeFiltersMap.COMMENT_REPLY
        )
      )
    } else {
      setFilteredNotifications(
        notificationsList.filter((item) => item.notificationType === filter)
      )
    }
  }
  const countFilterType = (filter) => {
    if (filter === activeFiltersMap.ALL) {
      return notificationsList.length
    } else if (
      filter === activeFiltersMap.NEW_COMMENT ||
      filter === activeFiltersMap.COMMENT_REPLY
    ) {
      return notificationsList.filter(
        (item) =>
          item.notificationType === activeFiltersMap.NEW_COMMENT ||
          item.notificationType === activeFiltersMap.COMMENT_REPLY
      ).length
    } else {
      return notificationsList.filter(
        (item) => item.notificationType === filter
      ).length
    }
  }

  const renderNotificationCard = (notification) => (
    <a
      href={notification.internalLink}
      className={cx("w-100", styles.notifications)}
      key={`notification-${notification.id}`}
    >
      <Col
        style={
          isTablet && user && user.type
            ? { width: `${widthContainerButtonsTablet}px` }
            : { width: `${widthContainerButtons}px` }
        }
        className={cx(
          " flex flex-column items-center",
          styles.notifications__wrapper
        )}
      >
        <div className="flex flex-row w-100">
          <Avatar
            src={`${process.env.S3_IMAGES}/${notification.userAvatar}`}
            className={cx("contain pointer mr10", styles.notifications__avatar)}
          />

          <div className="flex flex-column ml16 w-100 justify-center">
            <div className="flex flex-row justify-between">
              <label
                htmlFor=""
                className={cx("", styles.notifications__author)}
              >
                {notification?.userName}
              </label>
              <label
                className={cx("lh-copy ml16 f12", styles.notifications__date)}
              >
                {getTimeHumanized(notification.createdDate)}
              </label>
            </div>
            <p className={cx("", styles.notifications__message)}>
              {notification.message}{" "}
              <span className={styles.notifications__message__action}>
                {notification.title}
              </span>
            </p>
          </div>
        </div>
      </Col>
    </a>
  )

  return (
    <TranslateWrapper>
      <Helmet>
        <title>Notificações - Anonimatta</title>
      </Helmet>
      {isDesktop ? (
        <div className="page">
          <div className="page__center page__notifications flex flex-column">
            {" "}
            <section
              className={cx(" ", styles.notifications__title__container)}
            >
              <h3
                style={
                  isTablet && user && user.type
                    ? { width: `${widthContainerButtonsTablet}px` }
                    : { width: `${widthContainerButtons}px` }
                }
                className={styles.notifications__title}
              >
                {translate("notificationsTitle")}
              </h3>
            </section>
            <section className="notifications__area ">
              <Row
                style={
                  isTablet && user && user.type
                    ? { width: `${widthContainerButtonsTablet}px` }
                    : { width: `${widthContainerButtons}px` }
                }
                className={cx(
                  "notifications__buttons",
                  styles.notifications_list_buttons
                )}
              >
                {" "}
                {options.map((item) => {
                  return (
                    <Button
                      key={item.value}
                      style={
                        isTablet && user && user.type
                          ? { width: "100px", fontSize: "12px" }
                          : {}
                      }
                      type={activeFilter === item.value ? "primary" : "default"}
                      className={cx(styles.filterButton)}
                      onClick={() => setFilter(item.value)}
                    >
                      {item.label}
                    </Button>
                  )
                })}
              </Row>
            </section>
            <section
              style={
                isTablet && user && user.type
                  ? { width: `${widthContainerNotificationsTablet}px` }
                  : { width: `${widthContainerNotifications}px` }
              }
              className={cx(
                "flex flex-column",
                styles.notifications__container
              )}
            >
              {filteredNotifications.map((item) =>
                renderNotificationCard(item)
              )}
            </section>{" "}
          </div>
        </div>
      ) : (
        <div
          className={cx(
            " page__notifications__mobile flex flex-column items-center"
          )}
        >
          <div
            className={cx(
              " flex flex-column items-center",
              styles.notifications__mobile
            )}
          >
            <div className={styles.notifications__titleAndSelect}>
              <section
                className={cx(" ", styles.notifications__title__container)}
              >
                <h3 className={styles.notifications__title}>
                  {translate("notificationsTitle")}
                </h3>
              </section>
              <section className="notifications__area w-100 ">
                <SelectMenu
                  defaultValue={"ALL"}
                  options={options}
                  setFilter={(value) => setFilter(value)}
                  countFilterType={(value) => countFilterType(value)}
                />
              </section>
            </div>
            <section
              className={cx(
                "flex flex-column",
                styles.notifications__container
              )}
            >
              {filteredNotifications.map((item) =>
                renderNotificationCard(item)
              )}
            </section>{" "}
          </div>
        </div>
      )}
    </TranslateWrapper>
  )
}

export default NotificationsPage
