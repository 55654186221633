import { Select } from "antd"
import cx from "classnames"
import * as React from "react"

import styles from "./style.module.less"

const { Option } = Select

const SelectMenu = ({ options, setFilter, countFilterType, defaultValue }) => {
  const [isOpenSelect, setIsOpenSelect] = React.useState(false)
  const numberOption = options.length
  const heightDropdown = numberOption * 50
  console.log(options)

  const handleChange = (value) => {
    setFilter(value)
  }

  const handleOpenSelect = () => {
    setIsOpenSelect(!isOpenSelect)
  }

  const iconRender = () => {
    return (
      <img
        src={require("@images/icons/downArrowSelect.svg")}
        style={{ height: 35, width: 35, cursor: "pointer" }}
        alt="Seta pra baixo"
        className={isOpenSelect ? styles.select__icon : ""}
      />
    )
  }
  const renderCounterOption = (value) => {
    let count = 0
    count = countFilterType ? countFilterType(value) : 0
    if (count) {
      return (
        <label htmlFor="" className={styles.select__option__count}>
          {count}
        </label>
      )
    }
    return null
  }
  const renderOptions = () =>
    options.map((item) => (
      <Option
        key={item.value}
        value={item.value}
        label={item.label}
        className="selectMenuMobileOption"
      >
        <div
          className={cx(
            "flex flex-row justify-between select__option",
            styles.select__option
          )}
        >
          <label htmlFor="">{item.label}</label>
          {renderCounterOption(item.value)}
        </div>
      </Option>
    ))
  return (
    <Select
      id="select"
      className="selectMenuMobile"
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={handleChange}
      listHeight={heightDropdown}
      dropdownStyle={{
        borderRadius: "16px",
        backgroundColor: "#FFFF",
        border: "2px solid #E6E8EC",
        boxSizing: "border-box",
        padding: 0,
        boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.1)",
        overflow: "unset",
        height: "auto",
      }}
      optionLabelProp="label"
      suffixIcon={iconRender()}
      open={isOpenSelect}
      onClick={() => handleOpenSelect()}
    >
      {renderOptions()}
    </Select>
  )
}
export default SelectMenu
