import { useEffect, useState } from "react"

import { getWindow, isBrowser } from "../utils/getWindow"

export const LOCAL_NOTIFICATIONS = "localNewNotifications"

export const useNotificationsCounter = () => {
  let loadedNotifications = 0
  if (isBrowser()) {
    loadedNotifications =
      JSON.parse(getWindow().localStorage.getItem(LOCAL_NOTIFICATIONS)) || 0
  }
  const [counter, setCounter] = useState(loadedNotifications)

  function save() {
    getWindow().localStorage.setItem(
      LOCAL_NOTIFICATIONS,
      JSON.stringify(counter)
    )
  }

  function incrementCounter() {
    setCounter((oldCounter) => oldCounter + 1)
  }

  function getCounter() {
    return counter
  }

  function clearCounter() {
    setCounter(0)
    getWindow().localStorage.setItem(LOCAL_NOTIFICATIONS, JSON.stringify(0))
  }

  useEffect(() => {
    return () => {
      save()
    }
  }, [])

  return [incrementCounter, getCounter, clearCounter]
}
